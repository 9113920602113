var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "admin-view" },
    [
      _vm._m(0),
      _c(
        "b-card",
        [
          _c("b-card-header", [_vm._v("System")]),
          _c("div", { staticClass: "admin-section" }, [
            _c("div", { staticClass: "admin-item" }, [
              _c("i", {
                staticClass: "large-icon",
                class: _vm.$config.icons.admin.queue_health,
                on: { click: _vm.clickSysCtrl }
              }),
              _vm._v("System Control ")
            ]),
            _c("div", { staticClass: "admin-item" }, [
              _c("i", {
                staticClass: "large-icon",
                class: _vm.$config.icons.admin.performance,
                on: { click: _vm.clickPerformance }
              }),
              _vm._v("Performance Stats ")
            ]),
            _c(
              "div",
              { staticClass: "admin-usage-stats admin-item-widget" },
              [
                _vm.usageStatistics
                  ? _c("statistics-panel", {
                      attrs: { statistics: _vm.usageStatistics }
                    })
                  : _vm._e()
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "admin-item-widget" },
              [
                _c("admin-queue-health-widget", {
                  attrs: {
                    "queue-names": ["ProductionQueue", "DevelopmentQueue"]
                  }
                })
              ],
              1
            )
          ])
        ],
        1
      ),
      _c(
        "b-card",
        [
          _c("b-card-header", [_vm._v("Devices")]),
          _c("div", { staticClass: "admin-section" }, [
            _c("div", { staticClass: "admin-item" }, [
              _c("i", {
                staticClass: "large-icon",
                class: _vm.$config.icons.device.base,
                on: { click: _vm.clickDevices }
              }),
              _vm._v("Device List ")
            ]),
            _c("div", { staticClass: "admin-item" }, [
              _c("i", {
                directives: [
                  {
                    name: "b-tooltip",
                    rawName: "v-b-tooltip.hover",
                    modifiers: { hover: true }
                  }
                ],
                staticClass: "large-icon",
                class: _vm.$config.icons.device.bulkAdd,
                attrs: { title: "Add Many Devices" },
                on: { click: _vm.clickBulkAdd }
              }),
              _vm._v(" Bulk Add ")
            ]),
            _c("div", { staticClass: "admin-item" }, [
              _c("i", {
                directives: [
                  {
                    name: "b-tooltip",
                    rawName: "v-b-tooltip.hover",
                    modifiers: { hover: true }
                  }
                ],
                staticClass: "large-icon",
                class: _vm.$config.icons.device.assign,
                attrs: { title: "Assign Many Devices" },
                on: { click: _vm.clickAssign }
              }),
              _vm._v(" Assign Devices ")
            ]),
            _c("div", { staticClass: "admin-item" }, [
              _c(
                "div",
                {
                  staticClass: "icon-composite",
                  on: { click: _vm.clickUnassigned }
                },
                [
                  _c("i", {
                    directives: [
                      {
                        name: "b-tooltip",
                        rawName: "v-b-tooltip.hover",
                        modifiers: { hover: true }
                      }
                    ],
                    staticClass:
                      "mx-1 icon-composite-main icon-composite-highlight",
                    class: _vm.$config.icons.device.base,
                    attrs: { title: "Unassigned Device List" }
                  }),
                  _c("i", {
                    staticClass:
                      "fa fa-question mx-1 icon-composite-child icon-composite-highlight"
                  })
                ]
              ),
              _vm._v(" Unassigned ")
            ]),
            _c("div", { staticClass: "admin-item" }, [
              _c("i", {
                directives: [
                  {
                    name: "b-tooltip",
                    rawName: "v-b-tooltip.hover",
                    modifiers: { hover: true }
                  }
                ],
                staticClass: "large-icon",
                class: _vm.$config.icons.device.unhealthy,
                attrs: { title: "Unhealthy Devices" },
                on: { click: _vm.clickUnhealthy }
              }),
              _vm._v(" Unhealthy ")
            ]),
            _c("div", { staticClass: "admin-item" }, [
              _c("i", {
                directives: [
                  {
                    name: "b-tooltip",
                    rawName: "v-b-tooltip.hover",
                    modifiers: { hover: true }
                  }
                ],
                staticClass: "large-icon",
                class: _vm.$config.icons.device.commands,
                attrs: { title: "Send Device Commands" },
                on: { click: _vm.clickCommands }
              }),
              _vm._v(" Commands ")
            ]),
            _c("div", { staticClass: "admin-item" }, [
              _c("i", {
                directives: [
                  {
                    name: "b-tooltip",
                    rawName: "v-b-tooltip.hover",
                    modifiers: { hover: true }
                  }
                ],
                staticClass: "large-icon",
                class: _vm.$config.icons.device.sim,
                attrs: { title: "SIM Card Admin" },
                on: { click: _vm.clickSIM }
              }),
              _vm._v(" SIM Cards ")
            ]),
            _c("div", { staticClass: "admin-item" }, [
              _c("i", {
                staticClass: "large-icon",
                class: _vm.$config.icons.device.archived,
                on: { click: _vm.clickArchivedDevices }
              }),
              _vm._v(" Archived Devices ")
            ])
          ])
        ],
        1
      ),
      _c(
        "b-card",
        [
          _c("b-card-header", [_vm._v("Users")]),
          _c("div", { staticClass: "admin-section" }, [
            _c("div", { staticClass: "admin-item" }, [
              _c("i", {
                staticClass: "large-icon",
                class: _vm.$config.icons.user.base,
                on: { click: _vm.clickUsers }
              }),
              _vm._v("User List ")
            ]),
            _c("div", { staticClass: "admin-item" }, [
              _c("i", {
                staticClass: "large-icon",
                class: _vm.$config.icons.user.bulkAdd,
                on: { click: _vm.clickBulkUsers }
              }),
              _vm._v("Bulk Add ")
            ]),
            _c("div", { staticClass: "admin-item" }, [
              _c("i", {
                staticClass: "large-icon",
                class: _vm.$config.icons.general.products,
                on: { click: _vm.clickProducts }
              }),
              _vm._v("Products ")
            ])
          ])
        ],
        1
      ),
      _c(
        "b-card",
        [
          _c("b-card-header", [_vm._v("Shop")]),
          _c("div", { staticClass: "admin-section" }, [
            _c("div", { staticClass: "admin-item" }, [
              _c("i", {
                staticClass: "large-icon",
                class: _vm.$config.icons.shopify.shopProducts,
                on: { click: _vm.clickShopProducts }
              }),
              _vm._v("Shop Products ")
            ]),
            _c("div", { staticClass: "admin-item" }, [
              _c("i", {
                staticClass: "large-icon",
                class: _vm.$config.icons.shopify.shopOrders,
                on: { click: _vm.clickShopOrders }
              }),
              _vm._v("Shop Orders ")
            ])
          ])
        ],
        1
      ),
      _c(
        "b-card",
        [
          _c("b-card-header", [_vm._v("Stats and Task System")]),
          _c("div", { staticClass: "admin-section" }, [
            _c(
              "div",
              { staticClass: "admin-item" },
              [
                _c("router-link", { attrs: { to: "./admin/tasks" } }, [
                  _c("i", {
                    staticClass: "large-icon",
                    class: _vm.$config.icons.admin.tasks
                  })
                ]),
                _vm._v(" Async Tasks ")
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "admin-item" },
              [
                _c("router-link", { attrs: { to: "./admin/rq-status" } }, [
                  _c("i", {
                    staticClass: "large-icon",
                    class: _vm.$config.icons.admin.rq_jobs
                  })
                ]),
                _vm._v(" RQ Status ")
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "admin-item" },
              [
                _c("router-link", { attrs: { to: "./admin/stats-coverage" } }, [
                  _c("i", {
                    staticClass: "large-icon",
                    class: _vm.$config.icons.stats.coverage
                  })
                ]),
                _vm._v(" Stats Coverage ")
              ],
              1
            )
          ])
        ],
        1
      ),
      _c(
        "b-card",
        [
          _c("b-card-header", [_vm._v("Forms System")]),
          _c("div", { staticClass: "admin-section" }, [
            _c(
              "div",
              { staticClass: "admin-item" },
              [
                _c("router-link", { attrs: { to: "./admin/templates" } }, [
                  _c("i", {
                    staticClass: "large-icon",
                    class: _vm.$config.icons.admin.templates
                  })
                ]),
                _vm._v(" Templates ")
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "admin-item" },
              [
                _c("router-link", { attrs: { to: "./admin/forms" } }, [
                  _c("i", {
                    staticClass: "large-icon",
                    class: _vm.$config.icons.admin.forms
                  })
                ]),
                _vm._v(" Forms Data ")
              ],
              1
            )
          ])
        ],
        1
      ),
      _c(
        "b-card",
        [
          _c("b-card-header", [_vm._v("Links System")]),
          _c("div", { staticClass: "admin-section" }, [
            _c(
              "div",
              { staticClass: "admin-item" },
              [
                _c("router-link", { attrs: { to: "./admin/links" } }, [
                  _c("i", {
                    staticClass: "large-icon",
                    class: _vm.$config.icons.admin.links
                  })
                ]),
                _vm._v(" Data Links ")
              ],
              1
            )
          ])
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "admin-title" }, [
      _c("h1", [_vm._v("Admin Panel")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }