var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "admin-queue-health-widget" },
    [
      _c("div", { staticClass: "title" }, [_vm._v("Queue Health")]),
      _vm._l(_vm.bars, function(bar, idx) {
        return _c(
          "div",
          { key: idx, staticClass: "bar-container" },
          [
            _c("div", { staticClass: "bar-title" }, [
              _vm._v(_vm._s(bar.barTitle))
            ]),
            _c(
              "b-progress-bar",
              {
                attrs: {
                  value: bar.barValue,
                  variant: bar.barVariant,
                  height: "40px"
                }
              },
              [_vm._v(_vm._s(bar.barText))]
            )
          ],
          1
        )
      })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }