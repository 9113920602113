<template>
  <div class="admin-queue-health-widget">
    <div class="title">Queue Health</div>
    <div v-for="(bar, idx) of bars" :key="idx" class="bar-container">
      <div class="bar-title">{{bar.barTitle}}</div>
      <b-progress-bar :value="bar.barValue" :variant="bar.barVariant" height="40px">{{bar.barText}}</b-progress-bar>
    </div>
  </div>
</template>

<script>
import moment from 'moment'
import * as DataProvider from '../../components/helpers/DataProvider'
import * as ErrorHelper from '../../components/helpers/ErrorHelper'

export default {
  name: 'admin-queue-health-widget',
  components: {},
  props: {
    queueNames: Array
  },
  data: function () {
    return {
      bars: [
        {
          barTitle: 'Loading...',
          barVariant: 'success',
          barValue: 0,
          barText: ''
        }
      ],
      update_interval: null
    }
  },
  mounted () {
    if (!this.queueNames) {
      this.queueNames = ['ProductionQueue']
    }
    this.bars = []
    this.queueNames.forEach(queueName => this.bars.push({
      barTitle: queueName,
      barVariant: 'success',
      barValue: 0,
      barText: ''
    }))
    this.refreshQueues()
    this.startTimer()
  },
  methods: {
    async refreshQueues () {
      this.queueNames.forEach(queueName => this.updateBar(queueName))
    },
    async updateBar (queueName) {
      let barData = this.bars.find(x => x.barTitle === queueName)
      let response = await DataProvider.adminGetQueueHealth(queueName)
      if (response.success) {
        barData.barTitle = queueName
        barData.barValue = Math.min((Math.max(25, response.data.ApproximateNumberOfMessages) / 5), 100)
        barData.barText = response.data.ApproximateNumberOfMessages
        barData.barVariant = 'success'
        if (response.data.ApproximateNumberOfMessages >= this.$config.queueHealth.dangerThreshold) {
          barData.barVariant = 'danger'
        } else if (response.data.ApproximateNumberOfMessages >= this.$config.queueHealth.warningThreshold) {
          barData.barVariant = 'warning'
        }
      } else {
        ErrorHelper.displayDataErrorToast(response)
      }
    },
    formatTimestamp (timestamp) {
      return moment(timestamp).format('HH:mm')
    },
    startTimer () {
      this.update_interval = setInterval(() => this.refreshQueues(), this.$config.queueHealth.updateInterval)
    },
    stopTimer () {
      clearInterval(this.update_interval)
    }
  },
  beforeDestroy () {
    this.stopTimer()
  }
}
</script>

<style scoped lang="scss">
@import '../../variables';
.admin-queue-health-widget {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 2%;
  overflow-y: auto;
  border: solid 3px $theme-color-primary-3;
}

.title {
  font-weight: 500;
  text-decoration: underline;
  color: $theme-color-primary-3;
}

.bar-container {
  width: 100%;
}

.bar-title {
  text-align: left;
  font-size: 1rem;
}
</style>
