<template>
  <div class="admin-view">
    <div class="admin-title">
      <h1>Admin Panel</h1>
    </div>
    <b-card>
      <b-card-header>System</b-card-header>
      <div class="admin-section">
        <div class="admin-item">
          <i
            class="large-icon"
            :class="$config.icons.admin.queue_health"
            @click="clickSysCtrl"
          ></i
          >System Control
        </div>
        <div class="admin-item">
          <i
            class="large-icon"
            :class="$config.icons.admin.performance"
            @click="clickPerformance"
          ></i
          >Performance Stats
        </div>
        <div class="admin-usage-stats admin-item-widget">
          <statistics-panel
            v-if="usageStatistics"
            :statistics="usageStatistics"
          ></statistics-panel>
        </div>
        <div class="admin-item-widget">
          <admin-queue-health-widget
            :queue-names="['ProductionQueue', 'DevelopmentQueue']"
          ></admin-queue-health-widget>
        </div>
      </div>
    </b-card>
    <b-card>
      <b-card-header>Devices</b-card-header>
      <div class="admin-section">
        <div class="admin-item">
          <i
            class="large-icon"
            :class="$config.icons.device.base"
            @click="clickDevices"
          ></i
          >Device List
        </div>
        <div class="admin-item">
          <i
            class="large-icon"
            :class="$config.icons.device.bulkAdd"
            @click="clickBulkAdd"
            v-b-tooltip.hover
            title="Add Many Devices"
          ></i>
          Bulk Add
        </div>
        <div class="admin-item">
          <i
            class="large-icon"
            :class="$config.icons.device.assign"
            @click="clickAssign"
            v-b-tooltip.hover
            title="Assign Many Devices"
          ></i>
          Assign Devices
        </div>
        <div class="admin-item">
          <div class="icon-composite" @click="clickUnassigned">
            <i
              class="mx-1 icon-composite-main icon-composite-highlight"
              :class="$config.icons.device.base"
              v-b-tooltip.hover
              title="Unassigned Device List"
            ></i>
            <i
              class="
                fa fa-question
                mx-1
                icon-composite-child icon-composite-highlight
              "
            ></i>
          </div>
          Unassigned
        </div>
        <div class="admin-item">
          <i
            class="large-icon"
            :class="$config.icons.device.unhealthy"
            @click="clickUnhealthy"
            v-b-tooltip.hover
            title="Unhealthy Devices"
          ></i>
          Unhealthy
        </div>
        <div class="admin-item">
          <i
            class="large-icon"
            :class="$config.icons.device.commands"
            @click="clickCommands"
            v-b-tooltip.hover
            title="Send Device Commands"
          ></i>
          Commands
        </div>
        <div class="admin-item">
          <i
            class="large-icon"
            :class="$config.icons.device.sim"
            @click="clickSIM"
            v-b-tooltip.hover
            title="SIM Card Admin"
          ></i>
          SIM Cards
        </div>
        <div class="admin-item">
          <i
            class="large-icon"
            :class="$config.icons.device.archived"
            @click="clickArchivedDevices"
          ></i>
          Archived Devices
        </div>
      </div>
    </b-card>
    <b-card>
      <b-card-header>Users</b-card-header>
      <div class="admin-section">
        <div class="admin-item">
          <i
            class="large-icon"
            :class="$config.icons.user.base"
            @click="clickUsers"
          ></i
          >User List
        </div>
        <div class="admin-item">
          <i
            class="large-icon"
            :class="$config.icons.user.bulkAdd"
            @click="clickBulkUsers"
          ></i
          >Bulk Add
        </div>
        <div class="admin-item">
          <i
            class="large-icon"
            :class="$config.icons.general.products"
            @click="clickProducts"
          ></i
          >Products
        </div>
      </div>
    </b-card>
    <b-card>
      <b-card-header>Shop</b-card-header>
      <div class="admin-section">
        <div class="admin-item">
          <i
            class="large-icon"
            :class="$config.icons.shopify.shopProducts"
            @click="clickShopProducts"
          ></i
          >Shop Products
        </div>
        <div class="admin-item">
          <i
            class="large-icon"
            :class="$config.icons.shopify.shopOrders"
            @click="clickShopOrders"
          ></i
          >Shop Orders
        </div>
      </div>
    </b-card>
    <b-card>
      <b-card-header>Stats and Task System</b-card-header>
      <div class="admin-section">
        <div class="admin-item">
          <router-link to="./admin/tasks">
            <i
              class="large-icon"
              :class="$config.icons.admin.tasks"
            ></i
            >
          </router-link>
          Async Tasks
        </div>
        <div class="admin-item">
          <router-link to="./admin/rq-status">
            <i
              class="large-icon"
              :class="$config.icons.admin.rq_jobs"
            ></i>
          </router-link>
          RQ Status
        </div>
        <div class="admin-item">
          <router-link to="./admin/stats-coverage">
            <i
              class="large-icon"
              :class="$config.icons.stats.coverage"
            ></i>
          </router-link>
          Stats Coverage
        </div>
      </div>
    </b-card>
    <b-card>
      <b-card-header>Forms System</b-card-header>
      <div class="admin-section">
        <div class="admin-item">
          <router-link to="./admin/templates">
            <i
              class="large-icon"
              :class="$config.icons.admin.templates"
            ></i
            >
          </router-link>
          Templates
        </div>
        <div class="admin-item">
          <router-link to="./admin/forms">
            <i
              class="large-icon"
              :class="$config.icons.admin.forms"
            ></i>
          </router-link>
          Forms Data
        </div>
      </div>
    </b-card>
    <b-card>
      <b-card-header>Links System</b-card-header>
      <div class="admin-section">
        <div class="admin-item">
          <router-link to="./admin/links">
            <i
              class="large-icon"
              :class="$config.icons.admin.links"
            ></i
            >
          </router-link>
          Data Links
        </div>
      </div>
    </b-card>
  </div>
</template>

<script>
// @ is an alias to /src
// import HelloWorld from '@/components/HelloWorld.vue'
import AdminQueueHealthWidget from '@/components/admin/AdminQueueHealthWidget'
import StatisticsPanel from '@/components/stats/StatisticsPanel.vue'
import * as DataProvider from '@/components/helpers/DataProvider'
export default {
  name: 'admin-view',
  components: {
    AdminQueueHealthWidget,
    StatisticsPanel
  },
  data: function () {
    return {
      usageStatistics: null
    }
  },
  methods: {
    clickUsers: function () {
      this.$router.push({ path: '/admin/users' })
    },
    clickDevices: function () {
      this.$router.push({ path: '/admin/devices' })
    },
    clickArchivedDevices: function () {
      this.$router.push({ path: '/admin/archived-devices' })
    },
    clickUnassigned: function () {
      this.$router.push({ path: '/admin/devices/unassigned' })
    },
    clickBulkAdd: function () {
      this.$router.push({ path: '/admin/devices/bulkadd' })
    },
    clickUnhealthy: function () {
      this.$router.push({ path: '/admin/devices/unhealthy' })
    },
    clickCommands: function () {
      this.$router.push({ path: '/admin/devices/commands' })
    },
    clickSIM: function () {
      this.$router.push({ path: '/admin/sim-cards' })
    },
    clickBulkUsers: function () {
      this.$router.push({ path: '/admin/users/bulkadd' })
    },
    clickProducts: function () {
      this.$router.push({ path: '/admin/products' })
    },
    clickShopProducts: function () {
      this.$router.push({ path: '/admin/shop-products' })
    },
    clickShopOrders: function () {
      this.$router.push({ path: '/admin/shop-orders' })
    },
    clickSysCtrl: function () {
      this.$router.push({ path: 'admin/system-controls' })
    },
    clickPerformance: function () {
      this.$router.push({ path: 'admin/perf-stats' })
    },
    clickAssign: function () {
      this.$router.push({ path: 'admin/devices/assign' })
    }
  },
  created: async function () {
    let usageData = await DataProvider.adminUsageStatistics()
    this.usageStatistics = usageData.data.statistics
  }
}
</script>

<style scoped lang="scss">
@import '@/variables';
.admin-view {
  width: 100%;
  overflow: scroll;
}
.card {
  margin: 8pt;
}
//.card-body{
//  display: flex;
//  flex-direction: row;
//  width: 100%;
//  overflow: auto;
//  justify-content: space-around;
//}

.admin-section {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
  padding: 10px 10px;
}

.admin-section-title {
  text-align: left;
  color: $theme-color-primary-3;
  font-size: 2.2rem;
  font-weight: 700;
}

.admin-title {
  color: $text-color-invert;
  font-size: 3rem;
  font-weight: 700;
}

.admin-item {
  display: flex;
  flex-direction: column;
  margin: 8pt;
  font-size: 2rem;
  font-weight: 700;
  color: $theme-color-primary-3;
}

.admin-item-widget {
  width: 20rem;
}

.large-icon {
  font-size: 10rem;
  color: $theme-color-primary-3;
  margin: 0px 20px;
  cursor: pointer;
}

.large-icon:hover,
.large-icon:focus {
  color: $theme-color-primary-2;
  text-shadow: 0px 0px 20px $theme-color-secondary-2,
    0px 0px 10px $theme-color-secondary-2;
}

.icon-composite {
  display: contents;
}

.icon-composite-main {
  font-size: 10rem;
  margin: 0px 20px;
  background-color: transparent;
  color: $theme-color-primary-3;
  text-align: center;
  cursor: pointer;
}

.icon-composite-child {
  position: relative;
  font-size: 10rem;
  top: -9rem;
  background-color: transparent;
  color: $warning;
  text-align: center;
  cursor: pointer;
  height: 0;
}

.icon-composite:focus,
.icon-composite:hover .icon-composite-highlight {
  color: $theme-color-primary-1 !important;
  //border-color: $theme-color-primary-3;
  //background: $theme-color-secondary-2;
  text-shadow: 0px 0px 20px $theme-color-secondary-2,
    0px 0px 10px $theme-color-secondary-2;
}
</style>
