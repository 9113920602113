var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "stats-controls stats-panel" },
    _vm._l(_vm.statistics, function(statisticsTable) {
      return _c(
        "div",
        { key: statisticsTable },
        [
          _c("h3", [_vm._v(_vm._s(statisticsTable["title"]))]),
          _c("b-table", {
            staticClass: "bv-table",
            attrs: {
              striped: "",
              hover: "",
              items: statisticsTable["items"],
              fields: _vm.fields
            },
            scopedSlots: _vm._u(
              [
                {
                  key: "head(selected)",
                  fn: function(row) {
                    return [_vm._v(" " + _vm._s(row) + " ")]
                  }
                }
              ],
              null,
              true
            )
          })
        ],
        1
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }