<template>
  <div class="stats-controls stats-panel">
    <div v-for="statisticsTable in statistics" v-bind:key="statisticsTable">
      <h3>{{ statisticsTable["title"] }}</h3>
      <b-table
        striped
        hover
        :items="statisticsTable['items']"
        :fields="fields"
        class="bv-table"
      >
        <!--   Select All Header             -->
        <template v-slot:head(selected)="row">
          {{ row }}
        </template>
      </b-table>
    </div>
  </div>
</template>

<script>
export default {
  name: 'stats-panel',
  props: ['statistics'],
  data: function () {
    return {
      fields: [
        {
          key: 'name',
          label: 'Name',
          thStyle: {
            display: 'none'
          }
        },
        {
          key: 'value',
          label: 'Value',
          thStyle: {
            display: 'none'
          }
        }
      ]
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import '@/variables';

</style>
